import React from "react";
import { Role, Audience as AudienceModel, Member as MemberModel, PermissionsSet } from "./Models.js";
import CheckboxSet, { FormOption } from "./CheckboxSet.js";
import SelectBoxSet from "./SelectBoxSet.js";
import { DetailsHeader as DetailsHeaderModel } from "./Models";
import DetailsHeader from "./DetailsHeader.js";
import cookie from 'react-cookies';

class MemberDetails extends React.Component {
	constructor(props) {
		super(props);

		this.handleParticipantChange = this.handleParticipantChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.addCrew = this.addCrew.bind(this);

		this.state = {
			member: null,
			participants: null,
			isAdmin: null,
			hasEditEvents: null,
			hasEditMembership: null,
			hasEditFiles: null,
			hasEditAllEvents: null,
			hasAllPermissions: null,
			hasEditAudiences: null,
			hasEditDetails: null,
			isActive: null,
			cast: [],
			crew: [],
			musicians: [],
			other: [],
			allCast: [],
			allCrew: [],
			allMusicians: [],
			allOther: [],
			newCast: "",
			newCrew: "",
			newMusicians: "",
			newOther: "",
			selectedCast: "*nothing*",
			selectedCrew: "*nothing*",
			selectedMusicians: "*nothing*",
			selectedOther: "*nothing*",
			audiences: [],
			roles: null,
		};
	}

	handleParticipantChange(e) {
		const name = e.target.name;
		const item = e.target.value;
		const isChecked = e.target.checked;

		let copy = JSON.parse(JSON.stringify(this.state[name]));
		let index = copy.findIndex((element) => element.value === item);
		let member = new FormOption(item, copy[index].display, isChecked);

		copy[index] = member;

		this.setState({
			[name]: copy
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		console.log("setting " + name + " to " + value);
		this.setState({
			[name]: value,
		});
	}

	handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.addCrew(e);
			e.preventDefault();
		}
	}

	addCrew(event) {
		const roleTypeName = event.target.name.replace("new", "");
		const roleType = ["Cast", "Crew", "Musicians", "Other"].findIndex((element) => element === roleTypeName);
		const crew = this.state[roleTypeName.toLowerCase()];
		const newRoleName = `new${roleTypeName}`;
		const selectedRoleName = `selected${roleTypeName}`;
		var newCrew = this.state[selectedRoleName] === "" ? this.state[newRoleName] : this.state[selectedRoleName];

		const member = this.state.member;

		if (member.roles.findIndex((element) => element.type === roleType && element.title === newCrew) < 0) {
			crew.push(new FormOption(newCrew, newCrew, true));

			this.setState({ [crew]: crew });

			member.roles.push(new Role(roleType, newCrew));

			this.updateSelection();
		}

		this.setState({ [selectedRoleName]: "*nothing*" });
	}

	handleSubmit = e => {
		e.preventDefault();

		var body = {};
		var participants = [];
		var i;

		//  extract the node list from the form
		//  it looks like an array, but lacks array methods
		var { cast, crew, musicians, other, isAdmin, isActive } = this.form;

		// TODO: there has to be a better way
		cast = cast && cast.length === undefined ? [cast] : cast;
		crew = crew && crew.length === undefined ? [crew] : crew;
		musicians = musicians && musicians.length === undefined ? [musicians] : musicians;
		other = other && other.length === undefined ? [other] : other;

		// convert node list to an array
		const castArray = cast ? Array.prototype.slice.call(cast) : [];
		const crewArray = crew ? Array.prototype.slice.call(crew) : [];
		const musiciansArray = musicians ? Array.prototype.slice.call(musicians) : [];
		const otherArray = other ? Array.prototype.slice.call(other) : [];

		// extract only the checked checkboxes
		const castCheckboxes = castArray.filter(input => input.checked);
		const crewCheckboxes = crewArray.filter(input => input.checked);
		const musiciansCheckboxes = musiciansArray.filter(input => input.checked);
		const otherCheckboxes = otherArray.filter(input => input.checked);

		// use .map() to extract the value from each checked checkbox
		const castValues = castCheckboxes.map(input => input.value);
		const crewValues = crewCheckboxes.map(input => input.value);
		const musiciansValues = musiciansCheckboxes.map(input => input.value);
		const otherValues = otherCheckboxes.map(input => input.value);

		for (i = 0; i < castValues.length; i++) {
			participants.push({ type: 0, title: castValues[i] });
		}

		for (i = 0; i < crewValues.length; i++) {
			participants.push({ type: 1, title: crewValues[i] });
		}

		for (i = 0; i < musiciansValues.length; i++) {
			participants.push({ type: 2, title: musiciansValues[i] });
		}

		for (i = 0; i < otherValues.length; i++) {
			participants.push({ type: 3, title: otherValues[i] });
		}

		body.member = {};
		body.member.roles = participants;
		body.member.accessCode = this.props.match.params.id;
		body.member.isActive = isActive.checked && true;
		body.member.isAdmin = isAdmin.checked && true;

		body.member.permissionSet = {};

		const hasAllPermissions = this.state.hasAllPermissions;

		body.member.permissionSet.hasAllPermissions = hasAllPermissions;
		body.member.permissionSet.hasEditDetails = hasAllPermissions || this.state.hasEditDetails;
		body.member.permissionSet.hasEditMembership = hasAllPermissions || this.state.hasEditMembership;
		body.member.permissionSet.hasEditAudiences = hasAllPermissions || this.state.hasEditAudiences;
		body.member.permissionSet.hasEditEvents = hasAllPermissions || this.state.hasEditEvents;
		body.member.permissionSet.hasEditFiles = hasAllPermissions || this.state.hasEditFiles;
		body.member.permissionSet.hasEditAllEvents = hasAllPermissions || this.state.hasEditAllEvents;
		body.member.permissionSet.audienceIds = hasAllPermissions || this.state.hasEditAllEvents ? [] : this.state.audiences.filter((e) => e.checked === true).map((e) => e.value);

		body.sessionId = cookie.load('session_id');

		console.log(body);

		if (this.props.match.params.id === "new") {
			fetch(`https://beta.breaklegs.com/api/v1/member/post2/`
				, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					body: JSON.stringify(body) // body data type must match "Content-Type" header
				}).then((response) => {
					return response.json();
				}).then((data) => {
					window.location.replace("/membership");
				});
		} else if (this.state.member.person === undefined && !body.member.isActive) {
			fetch(`https://beta.breaklegs.com/api/v1/member/delete2/`
				, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					body: JSON.stringify(body) // body data type must match "Content-Type" header
				}).then((response) => {
					return response.json();
				}).then((data) => {
					window.location.replace("/membership");
				});
		} else {
			fetch(`https://beta.breaklegs.com/api/v1/member/put2/`
				, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					body: JSON.stringify(body) // body data type must match "Content-Type" header
				}).then((response) => {
					return response.json();
				}).then((data) => {
					window.location.replace("/membership");
				});
		}
	};

	updateSelection() {
		const member = this.state.member;
		const allRoles = this.state.roles;
		const allCast = allRoles.filter(model => model.type === 0 && member.roles.findIndex((element) => element.type === 0 && element.title === model.title) < 0).map(model => new FormOption(model.title, model.title, false));
		const allCrew = allRoles.filter(model => model.type === 1 && member.roles.findIndex((element) => element.type === 1 && element.title === model.title) < 0).map(model => new FormOption(model.title, model.title, false));
		const allMusicians = allRoles.filter(model => model.type === 2 && member.roles.findIndex((element) => element.type === 2 && element.title === model.title) < 0).map(model => new FormOption(model.title, model.title, false));
		const allOther = allRoles.filter(model => model.type === 3 && member.roles.findIndex((element) => element.type === 3 && element.title === model.title) < 0).map(model => new FormOption(model.title, model.title, false));

		allCast.splice(0, 0, new FormOption("*nothing*", "Select or create new role...", false));
		allCrew.splice(0, 0, new FormOption("*nothing*", "Select or create new role...", false));
		allMusicians.splice(0, 0, new FormOption("*nothing*", "Select or create new role...", false));
		allOther.splice(0, 0, new FormOption("*nothing*", "Select or create new role...", false));

		allCast.push(new FormOption("", "Create New Role...", false));
		allCrew.push(new FormOption("", "Create New Role...", false));
		allMusicians.push(new FormOption("", "Create New Role...", false));
		allOther.push(new FormOption("", "Create New Role...", false));

		this.setState({ allCast, allCrew, allMusicians, allOther });
	}

	componentDidMount() {
		const request = async () => {
			const sessionId = cookie.load('session_id');

			await fetch(
				`https://beta.breaklegs.com/api/v1/role/list2/?session_id=${sessionId}&isTheatre=false`,
				{
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.setState({ roles: data.roles }));

			const newPermissionsSet = new PermissionsSet(false, false, false, false, false, false, false, []);
			const newMember = new MemberModel([], "", "TBD", false, true, newPermissionsSet);

			const member = (this.props.match.params.id === "new" ? newMember : this.props.members.find(
				model => model.accessCode === this.props.match.params.id
			));
			const isAdmin = member.isAdmin;
			const isActive = member.isActive;

			this.setState({ member, isAdmin, isActive });

			const roles = member.roles
				.map(
					role =>
						new Role(
							role["type"],
							role["title"],
						)
				);

			const cast = roles.filter(model => model.type === 0).map(model => new FormOption(model.title, model.title, true));
			const crew = roles.filter(model => model.type === 1).map(model => new FormOption(model.title, model.title, true));
			const musicians = roles.filter(model => model.type === 2).map(model => new FormOption(model.title, model.title, true));
			const other = roles.filter(model => model.type === 3).map(model => new FormOption(model.title, model.title, true));

			this.setState({ cast, crew, musicians, other });

			this.setState({
				hasEditEvents: member.permissionSet.hasEditEvents,
				hasEditMembership: member.permissionSet.hasEditMembership,
				hasEditFiles: member.permissionSet.hasEditFiles,
				hasEditAllEvents: member.permissionSet.hasEditAllEvents,
				hasAllPermissions: member.permissionSet.hasAllPermissions,
				hasEditAudiences: member.permissionSet.hasEditAudiences,
				hasEditDetails: member.permissionSet.hasEditDetails,
			});

			const audiences = this.props.audiences.rooms.map(
				model => new AudienceModel(model["title"], model["id"])
			).map(model => (
				new FormOption(model.id, model.title, member.permissionSet.audienceIds.findIndex((element) => element === model.id) >= 0)
			));

			this.setState({ audiences });

			this.updateSelection();
		}

		request();
	}

	render() {
		if (this.state.member) {
			const cast = this.state.cast;
			const crew = this.state.crew;
			const musicians = this.state.musicians;
			const other = this.state.other;

			let specificCast =
				<React.Fragment>
					<fieldset>
						<legend>Cast</legend>
						<CheckboxSet setName={"cast"} setOptions={cast} onChange={this.handleParticipantChange} />

						<label>Add a new Cast role: <SelectBoxSet value={this.state.selectedCast} setName={"selectedCast"} setOptions={this.state.allCast} onChange={this.handleInputChange} /></label>

						{
							(this.state.selectedCast !== "")
								? null
								: <input type="text" placeholder="Role Title" name="newCast" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} autoComplete="off" />
						}

						{
							(this.state.selectedCast === "*nothing*" || (this.state.selectedCast === "" && this.state.newCast === ""))
								? null
								: <button name="Cast" type="button" onClick={this.addCrew} className="inline">Add Role</button>
						}
					</fieldset>
				</React.Fragment>;

			let specificCrew =
				<React.Fragment>
					<fieldset>
						<legend>Crew</legend>
						<CheckboxSet setName={"crew"} setOptions={crew} onChange={this.handleParticipantChange} />

						<label>Add a new Crew role: <SelectBoxSet value={this.state.selectedCrew} setName={"selectedCrew"} setOptions={this.state.allCrew} onChange={this.handleInputChange} /></label>

						{
							(this.state.selectedCrew !== "")
								? null
								: <input type="text" placeholder="Role Title" name="newCrew" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} autoComplete="off" />
						}

						{
							(this.state.selectedCrew === "*nothing*" || (this.state.selectedCrew === "" && this.state.newCrew === ""))
								? null
								: <button name="Crew" type="button" onClick={this.addCrew} className="inline">Add Role</button>
						}
					</fieldset>
				</React.Fragment >;

			let specificMusicians =
				<React.Fragment>
					<fieldset>
						<legend>Musicians</legend>
						<CheckboxSet setName={"musicians"} setOptions={musicians} onChange={this.handleParticipantChange} />

						<label>Add a new Musicians role: <SelectBoxSet value={this.state.selectedMusicians} setName={"selectedMusicians"} setOptions={this.state.allMusicians} onChange={this.handleInputChange} /></label>

						{
							(this.state.selectedMusicians !== "")
								? null
								: <input type="text" placeholder="Role Title" name="newMusicians" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} autoComplete="off" />
						}

						{
							(this.state.selectedMusicians === "*nothing*" || (this.state.selectedMusicians === "" && this.state.newMusicians === ""))
								? null
								: <button name="Musicians" type="button" onClick={this.addCrew} className="inline">Add Role</button>
						}
					</fieldset>
				</React.Fragment>;

			let specificOther =
				<React.Fragment>
					<fieldset>
						<legend>Other</legend>
						<CheckboxSet setName={"other"} setOptions={other} onChange={this.handleParticipantChange} />

						<label>Add a new Other role: <SelectBoxSet value={this.state.selectedOther} setName={"selectedOther"} setOptions={this.state.allOther} onChange={this.handleInputChange} /></label>

						{
							(this.state.selectedOther !== "")
								? null
								: <input type="text" placeholder="Role Title" name="newOther" onChange={this.handleInputChange} onKeyDown={this.handleKeyDown} autoComplete="off" />
						}

						{
							(this.state.selectedOther === "*nothing*" || (this.state.selectedOther === "" && this.state.newOther === ""))
								? null
								: <button name="Other" type="button" onClick={this.addCrew} className="inline">Add Role</button>
						}
					</fieldset>
				</React.Fragment>;

			const model = this.state.member;
			const detailsHeader = model.person ? new DetailsHeaderModel(model.person.name, `Member Code:`, `${model.accessCode}`, `person/${model.person.image}/full.jpg`)
				: new DetailsHeaderModel(`Member Code: ${model.accessCode}`, "", "", "no-avatar.png");
			const className = model.person ? "" : "no_person";

			return (
				<React.Fragment>
					<h1>{(this.props.match.params.id === "new" ? "New Member" : "Member Details")}</h1>

					<section class="member_details">
						<form onSubmit={this.handleSubmit} ref={form => (this.form = form)}>
							<DetailsHeader model={detailsHeader} className={className} />

							<fieldset className="simple">
								<legend>Admin</legend>
								<label>
									<input type="checkbox" name="isAdmin" checked={this.state.isAdmin} onChange={this.handleInputChange} />
									Is an admin
							</label>
							</fieldset>

							{
								(!this.state.isAdmin)
									? null
									: <fieldset>
										<legend>Permissions</legend>
										<label><input type="checkbox" name="hasAllPermissions" checked={this.state.hasAllPermissions} onChange={this.handleInputChange} /> All Permissions</label>

										{
											(this.state.hasAllPermissions)
												? null
												:
												<div>
													<label><input type="checkbox" name="hasEditDetails" checked={this.state.hasEditDetails} onChange={this.handleInputChange} /> Edit Details</label>
													<label><input type="checkbox" name="hasEditMembership" checked={this.state.hasEditMembership} onChange={this.handleInputChange} /> Edit Membership</label>
													<label><input type="checkbox" name="hasEditAudiences" checked={this.state.hasEditAudiences} onChange={this.handleInputChange} /> Edit Audiences</label>
													<label><input type="checkbox" name="hasEditEvents" checked={this.state.hasEditEvents} onChange={this.handleInputChange} /> Edit Events</label>
													<label><input type="checkbox" name="hasEditFiles" checked={this.state.hasEditFiles} onChange={this.handleInputChange} /> Edit Files</label>
												</div>
										}
										{
											(this.state.hasAllPermissions || (!this.state.hasEditEvents && !this.state.hasEditFiles))
												? null
												:
												<fieldset>
													<legend>Events / Files</legend>
													<label><input type="checkbox" name="hasEditAllEvents" checked={this.state.hasEditAllEvents} onChange={this.handleInputChange} /> All Audiences (includes any audiences that are created in the future)</label>
													{
														(this.state.hasEditAllEvents)
															? null
															:
															<CheckboxSet setName={"audiences"} setOptions={this.state.audiences} onChange={this.handleParticipantChange} />
													}
												</fieldset>
										}
									</fieldset>
							}

							{specificCast}

							{specificCrew}

							{specificMusicians}

							{specificOther}

							<fieldset className="simple">
								<legend>Active</legend>
								<label>
									<input type="checkbox" name="isActive" checked={this.state.isActive} onChange={this.handleInputChange} />
									Is active
							</label>
							</fieldset>

							<button type="submit">Save</button>
						</form>
					</section>
				</React.Fragment>
			);
		}

		return null;
	}
}

export default MemberDetails;
