import React from "react";

class Event extends React.Component {
	render() {
		const starts = (new Date(this.props.model.starts)).toLocaleString(undefined, { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" });
		const ends = (new Date(this.props.model.ends)).toLocaleTimeString(undefined, { hour: "numeric", minute: "numeric" });

		return (
			<React.Fragment>
				<h3>{this.props.model.title}</h3>
				{(this.props.model.location.name === "" ? "" : <h4>{this.props.model.location.name}</h4>)}
				<p><time>{starts}</time> - <time>{ends}</time></p>
				{(this.props.model.notes === "" ? "" : <p>{this.props.model.notes}</p>)}
			</React.Fragment>
		);
	}
}

export default Event;
