import React from "react";
import SelectBoxSet from "./SelectBoxSet.js";
import { FormOption } from "./CheckboxSet.js";
import cookie from 'react-cookies';
import { Event as EventModel, Location } from "./Models.js";

class EventDetails extends React.Component {
	constructor(props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.addLocation = this.addLocation.bind(this);

		this.state = {
			title: null,
			startDate: null,
			startTime: null,
			endDate: null,
			endTime: null,
			notes: null,
			audienceId: null,
			locations: null,
			locationId: "",
			locationTitle: "",
			locationAddress: "",
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleKeyDown(e) {
		if (e.keyCode === 13) {
			// this.addLocation(e);
			e.preventDefault();
		}
	}

	addLocation(event) {
		event.preventDefault();

		var body = {};

		body.sessionId = cookie.load('session_id');

		const location = new Location("", this.state.locationTitle, this.state.locationAddress);

		body.location = location;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/location/post2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			location.id = data["id"];

			var locations = this.state.locations;
			const first = locations.shift();
			const last = locations.pop();

			locations.push(new FormOption(location.id, location.name));
			locations.sort((a, b) => { return (a.display.toLowerCase() === b.display.toLowerCase() ? 0 : a.display.toLowerCase() < b.display.toLowerCase() ? -1 : 1) });
			locations.splice(0, 0, first);
			locations.push(last);

			this.setState({ locations, locationId: location.id });
		});
	}

	handleSubmit = e => {
		e.preventDefault();

		var body = {};

		const sessionId = cookie.load('session_id');

		body.sessionId = sessionId;
		body.hasNotification = false;
		body.event = {};
		body.event.id = this.props.match.params.eventId;
		body.event.title = this.state.title;
		body.event.notes = this.state.notes;
		body.event.starts = (new Date(this.state.startDate.replace(/-/g, '/') + " " + this.state.startTime)).getTime() / 1000;
		body.event.ends = (new Date(this.state.endDate.replace(/-/g, '/') + " " + this.state.endTime)).getTime() / 1000;
		body.event.audienceId = this.state.audienceId;
		body.event.location = {};
		body.event.location.id = (this.state.locationId === "*nothing*" ? "" : this.state.locationId);

		console.log(body);

		if (this.props.match.params.eventId === "new") {
			fetch(`https://beta.breaklegs.com/api/v1/calendar/event/post2/`, {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify(body) // body data type must match "Content-Type" header
			}).then((response) => {
				return response.json();
			}).then((data) => {
				window.history.back();
			});
		}
		else {
			fetch(`https://beta.breaklegs.com/api/v1/calendar/event/put2/`, {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify(body) // body data type must match "Content-Type" header
			}).then((response) => {
				return response.json();
			}).then((data) => {
				window.history.back();
			});
		}
	}

	componentDidMount() {
		if (this.props.events) {
			const event = this.props.match.params.eventId === "new" ? new EventModel("new", this.props.match.params.id, new Date(), new Date(), "", "", new Location("*nothing*", "", "")) : this.props.events.find(event => event.id === this.props.match.params.eventId);
			const starts = new Date(event.starts);
			const ends = new Date(event.ends);

			const locations = this.props.locations.locations.map(location => new FormOption(location.id, location.name + (location.address ? ` (${location.address})` : "")));

			locations.splice(0, 0, new FormOption("*nothing*", "[No Location Set]"));
			locations.push(new FormOption("", "Create new location..."));

			this.setState({
				locations,
				title: event.title,
				startDate: "" + starts.getFullYear() + "-" + (starts.getMonth() < 9 ? "0" + (starts.getMonth() + 1) : starts.getMonth() + 1) + "-" + (starts.getDate() < 10 ? "0" + starts.getDate() : starts.getDate()),
				endDate: "" + ends.getFullYear() + "-" + (ends.getMonth() < 9 ? "0" + (ends.getMonth() + 1) : ends.getMonth() + 1) + "-" + (ends.getDate() < 10 ? "0" + ends.getDate() : ends.getDate()),
				startTime: "" + (starts.getHours() < 10 ? "0" + (starts.getHours()) : starts.getHours()) + ":" + (starts.getMinutes() < 10 ? "0" + (starts.getMinutes()) : starts.getMinutes()),
				endTime: "" + (ends.getHours() < 10 ? "0" + (ends.getHours()) : ends.getHours()) + ":" + (ends.getMinutes() < 10 ? "0" + (ends.getMinutes()) : ends.getMinutes()),
				notes: event.notes,
				audienceId: event.audienceId,
				locationId: event.location.id === "" ? "*nothing*" : event.location.id,
			});
		}
	}

	render() {
		if (this.state.title != null && this.props.audiences && this.props.locations) {
			const audiences = this.props.audiences.rooms.map(audience => new FormOption(audience.id, audience.title));

			return (
				<React.Fragment>
					<h1>{(this.props.match.params.eventId === "new" ? "New Event" : "Event Details")}</h1>

					<form onSubmit={this.handleSubmit} ref={form => (this.form = form)}>
						<fieldset className="simple">
							<legend>Event Title</legend>
							<label>Title: <input type="text" placeholder="(required)" name="title" value={this.state.title} onChange={this.handleInputChange} required autoComplete="off" /></label>
						</fieldset>

						<fieldset>
							<legend>Starts</legend>
							<label>Date: <input type="date" name="startDate" value={this.state.startDate} placeholder="YYYY-MM-DD" onChange={this.handleInputChange} required /></label>
							<label>Time: <input type="time" name="startTime" value={this.state.startTime} placeholder="hh:mm" onChange={this.handleInputChange} required /></label>
						</fieldset>
						<fieldset>
							<legend>Ends</legend>
							<label>Date: <input type="date" name="endDate" value={this.state.endDate} placeholder="YYYY-MM-DD" onChange={this.handleInputChange} required /></label>
							<label>Time: <input type="time" name="endTime" value={this.state.endTime} placeholder="hh:mm" onChange={this.handleInputChange} required /></label>
						</fieldset>

						<fieldset>
							<legend>Calendar &amp; Location</legend>
							<label>Calendar: <SelectBoxSet value={this.state.audienceId} setName={"audienceId"} setOptions={audiences} onChange={this.handleInputChange} /></label>

							<label>Location: <SelectBoxSet value={this.state.locationId} setName={"locationId"} setOptions={this.state.locations} onChange={this.handleInputChange} /></label>

							{
								(this.state.locationId !== "")
									? null
									: <form onSubmit={this.addLocation} ref={form => (this.form = form)}>
										<input type="text" placeholder="Location Title" name="locationTitle" onChange={this.handleInputChange} required autoComplete="off" />
										<input type="text" placeholder="Location Address" name="locationAddress" onChange={this.handleInputChange} autoComplete="off" />
										<button className="inline" type="submit">Add Location</button>
									</form>
							}
						</fieldset>

						<fieldset>
							<legend>Notes</legend>
							<textarea name="notes" value={this.state.notes} onChange={this.handleInputChange}></textarea>
						</fieldset>

						<button type="submit">Save</button>
					</form>
				</React.Fragment>
			);
		}

		return null;
	}
}

export default EventDetails;
