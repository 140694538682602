import React, { Fragment } from "react";

class DetailsHeader extends React.Component {
	render() {
		const style =
			this.props.model.image
				? {
					backgroundImage: `url(https://beta.breaklegs.com/images/${this.props.model.image}`,
				}
				: null;

		const Tag = this.props.tag !== undefined ? this.props.tag : "div";
		const className = "details_header " + (this.props.className || "");

		return (
			<Tag className={className}>
				<span style={style}></span>
				<div>
					{(this.props.model.name) ?
						<h3>{this.props.model.name}</h3>
						: null}
					{(this.props.model.secondary) ?
						<p className="secondary">{this.props.model.secondary}</p>
						: null}
					{(this.props.model.tertiary) ?
						<p className="tertiary">{this.props.model.tertiary}</p>
						: null}
				</div>
			</Tag>
		);
	}
}

export default DetailsHeader;
