import React, { Fragment } from "react";
import Event from "./Event.js";
import { Event as EventModel } from "./Models.js";
import { Link } from "react-router-dom";
import cookie from 'react-cookies';

class Events extends React.Component {
	constructor(props) {
		super(props);

		this.updateEvents = this.updateEvents.bind(this);
		this.duplicateEventDay = this.duplicateEventDay.bind(this);
		this.duplicateEventWeek = this.duplicateEventWeek.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);

		this.state = {
			title: null,
			events: null,
		};
	}

	updateEvents(events) {
		this.props.updateEvents(events);
	}

	duplicateEvent(e, days) {
		const id = e.target.dataset.id;
		const event = this.props.events.find(event => event.id === id);
		const copy = JSON.parse(JSON.stringify(event));
		const starts = new Date(copy.starts);
		const ends = new Date(copy.ends);

		starts.setDate(starts.getDate() + days);
		ends.setDate(ends.getDate() + days);

		copy.id = "";
		copy.starts = starts.getTime() / 1000;
		copy.ends = ends.getTime() / 1000;

		var body = {};

		body.sessionId = cookie.load('session_id');
		body.hasNotification = false;
		body.event = copy;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/calendar/event/post2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			window.location.reload();
		});
	}

	duplicateEventDay(e) {
		this.duplicateEvent(e, 1);
	}

	duplicateEventWeek(e) {
		this.duplicateEvent(e, 7);
	}

	deleteEvent(e) {
		const id = e.target.dataset.id;
		const event = this.props.events.find(event => event.id === id);

		var body = {};

		body.sessionId = cookie.load('session_id');
		body.hasNotification = false;
		body.event = event;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/calendar/event/delete2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			window.location.reload();
		});
	}

	componentDidMount() {
		const request = async () => {
			const sessionId = cookie.load('session_id');

			await fetch(
				`https://beta.breaklegs.com/api/v1/calendar/event/list2/?session_id=${sessionId}&audience_id=${this.props.match.params.id}`,
				{
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.updateEvents(data.events));

			const title = this.props.audiences.rooms.find(
				model => model.id === this.props.match.params.id
			).title;

			this.setState({ title });
			// }
		}

		request();
	}

	render() {
		if (this.props.events !== null) {
			console.log(this.props.events);

			const models = this.props.events.map(
				model => new EventModel(model["id"], model["audienceId"], model["starts"], model["ends"], model["title"], model["notes"], model["location"])
			);

			const listItems = models.map(model => (
				<li key={model.id}>
					<Link to={`/events/${this.props.match.params.id}/${model.id}`}>
						<Event model={model} />
					</Link>
					<button type="button" data-id={model.id} onClick={this.duplicateEventDay} className="inline tool copy">+ Day</button>
					<button type="button" data-id={model.id} onClick={this.duplicateEventWeek} className="inline tool copy">+ Week</button>
					<button type="button" data-id={model.id} onClick={this.deleteEvent} className="inline tool destructive">Delete</button>
				</li>
			));

			return (
				<Fragment>
					<h1>Events <Link to={`/events/${this.props.match.params.id}/new`}>+</Link></h1>
					<section className="events">
						<ul className="events">{listItems}</ul>
					</section>
				</Fragment>
			);
		}

		return null;
	}
}

export default Events;
