import React from "react";

// https://css-tricks.com/react-forms-using-refs/#react-refs-4

class CheckboxSet extends React.Component {
	render() {
		if (this.props.setOptions.length === 0) {
			return null;
		}
		else {
			return (
				<div>
					{this.props.setOptions.map(option => {
						return (
							<label key={option.value}>
								<input type="checkbox" value={option.value} name={this.props.setName} checked={option.checked} onChange={this.props.onChange} />
								{option.display}
							</label>
						);
					})}
				</div>
			);
		}
	}
}

export default CheckboxSet;

export class FormOption {
	constructor(value, display, checked) {
		this.value = value;
		this.display = display;
		this.checked = checked;
	}
}
