import React from "react";
import SelectBoxSet from "./SelectBoxSet.js";
import { FormOption } from "./CheckboxSet.js";
import cookie from 'react-cookies';
import { FileUpload as FileUploadModel } from "./Models.js";

class FileUploadDetails extends React.Component {
	constructor(props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.getFiles = this.getFiles.bind(this);
		this.handleDragOverEnter = this.handleDragOverEnter.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			title: null,
			created: null,
			createdTime: null,
			notes: null,
			audienceId: null,
			filesList: [],
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleDragOverEnter(event) {
		event.stopPropagation();
		event.preventDefault();
	}

	getFiles(event) {
		event.stopPropagation();
		event.preventDefault();

		const files = event.dataTransfer !== undefined ? event.dataTransfer.files : event.target.files;

		this.state.fileValue = event.target.value;

		if (event.dataTransfer) {
			this.setState({ fileValue: "" });
		}

		console.log("files");
		console.log(files);

		const filesList = [];

		for (let i = 0; i < files.length; i++) {
			let file = files[i];

			filesList.push(new FormOption(file.name, file.name, false));
		}

		this.setState({ files, filesList });
	}

	handleSubmit = e => {
		e.preventDefault();

		const files = this.state.files; // document.querySelector('[type=file]').files;
		const formData = new FormData();

		formData.append('sessionId', cookie.load('session_id'));
		formData.append('audienceId', this.props.match.params.id);

		for (let i = 0; i < files.length; i++) {
			let file = files[i];

			formData.append('file' + i, file);
		}

		fetch('https://beta.breaklegs.com/api/v1/file/upload/post2/', {
			method: 'POST',
			body: formData,
		}).then(response => {
			window.history.back();
		});
	}

	componentDidMount() {
		// if (this.props.files) {
		// 	const file = this.props.match.params.fileId === "new" ? new FileUploadModel("new", this.props.match.params.id, new Date(), "", "", "", "") : this.props.files.find(file => file.id === this.props.match.params.fileId);
		// 	const created = new Date(file.created);

		// 	this.setState({
		// 		title: file.title,
		// 		created: "" + created.getFullYear() + "-" + (created.getMonth() < 9 ? "0" + (created.getMonth() + 1) : created.getMonth() + 1) + "-" + (created.getDate() < 10 ? "0" + created.getDate() : created.getDate()),
		// 		createdTime: "" + (created.getHours() < 10 ? "0" + (created.getHours()) : created.getHours()) + ":" + (created.getMinutes() < 10 ? "0" + (created.getMinutes()) : created.getMinutes()),
		// 		notes: file.notes,
		// 		audienceId: file.audienceId,
		// 	});
		// }
	}

	render() {
		// if (this.state.title != null && this.props.audiences) {
		// const audiences = this.props.audiences.rooms.map(audience => new FormOption(audience.id, audience.title));

		return (
			<React.Fragment>
				<h1>{(this.props.match.params.fileId === "new" ? "Upload Files" : "File Details")}</h1>

				<section className="fileDetails">
					<form onSubmit={this.handleSubmit} ref={form => (this.form = form)}>
						<fieldset>
							{/* <legend>File Details</legend> */}
							{/* <label>Title: <input type="text" placeholder="(required)" name="title" value={this.state.title} onChange={this.handleInputChange} required autoComplete="off" /></label> */}

							<label>Files: <input type="file" value={this.state.fileValue} multiple onChange={this.getFiles} /></label>

							<div onDragEnter={this.handleDragOverEnter} onDragOver={this.handleDragOverEnter} onDrop={this.getFiles}>Or drag &amp; drop your files here...</div>

							<ol>
								{this.state.filesList.map(option => {
									return (
										<li key={option.value}>{option.display}</li>
									);
								})}
							</ol>

							{/* <fieldset>
							<legend>Starts</legend>
							<label>Date: <input type="date" name="startDate" value={this.state.startDate} placeholder="YYYY-MM-DD" onChange={this.handleInputChange} required /></label>
							<label>Time: <input type="time" name="startTime" value={this.state.startTime} placeholder="hh:mm" onChange={this.handleInputChange} required /></label>
						</fieldset> */}

							{/* <label>Audience: <SelectBoxSet value={this.state.audienceId} setName={"audienceId"} setOptions={audiences} onChange={this.handleInputChange} /></label> */}

							{/* <h3>Notes</h3> */}
							{/* <textarea name="notes" value={this.state.notes} onChange={this.handleInputChange}></textarea> */}
						</fieldset>

						<button type="submit">Upload</button>
					</form>
				</section>
			</React.Fragment>
		);
	}

	// return null;
	// }
}

export default FileUploadDetails;
