import React from "react";
import cookie from 'react-cookies'

class EditDetails extends React.Component {
	constructor(props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.previewFile = this.previewFile.bind(this);

		this.state = {
			title: null,
			starts: null,
			ends: null,
			image: null,
			notes: null,
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmit = e => {
		e.preventDefault();

		var body = {};
		const imgsrc = document.querySelector('img').src;

		body.sessionId = cookie.load('session_id');
		body.production = {};
		body.production.title = this.state.title;
		body.production.notes = this.state.notes;
		body.production.starts = (new Date(this.state.starts.replace(/-/g, '/'))).toISOString();
		body.production.ends = (new Date(this.state.ends.replace(/-/g, '/'))).toISOString();
		body.production.members = [];

		if (!(imgsrc.startsWith("http"))) {
			body.imageEncoded = imgsrc.substring(imgsrc.indexOf(",") + 1);
		}

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/production/put2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			alert("Saved!");
		});
	};

	componentDidMount() {
		if (this.props.production) {
			const starts = new Date(this.props.production.starts);
			const ends = new Date(this.props.production.ends);

			this.setState({
				title: this.props.production.title,
				starts: "" + starts.getFullYear() + "-" + (starts.getMonth() < 9 ? "0" + (starts.getMonth() + 1) : starts.getMonth() + 1) + "-" + (starts.getDate() < 9 ? "0" + (starts.getDate() + 1) : starts.getDate()),
				ends: "" + ends.getFullYear() + "-" + (ends.getMonth() < 9 ? "0" + (ends.getMonth() + 1) : ends.getMonth() + 1) + "-" + (ends.getDate() < 9 ? "0" + (ends.getDate() + 1) : ends.getDate()),
				image: this.props.production.image,
				notes: this.props.production.notes,
			});
		}
	}

	previewFile() {
		var preview = document.querySelector('img');
		var file = document.querySelector('input[type=file]').files[0];
		var reader = new FileReader();

		reader.addEventListener("load", function () {
			preview.src = reader.result;
		}, false);

		if (file) {
			reader.readAsDataURL(file);
		}
	}

	render() {
		if (this.state.title != null) {
			return (
				<React.Fragment>
					<h1>Edit Details</h1>

					<section className="details">
						<form onSubmit={this.handleSubmit} ref={form => (this.form = form)}>
							<fieldset className="simple">
								<legend>Playbill Image</legend>
								<label className="playbill">
									<img src={"https://beta.breaklegs.com/images/production/" + this.props.production.image + "/full.jpg"} alt="Playbill" />
									<span>edit</span>
									<input type="file" name="fileToUpload" accept="image/jpg,image/png,image/jpeg" onChange={this.previewFile} />
								</label>
							</fieldset>

							<fieldset className="simple">
								<legend>Production Title</legend>
								<label>Title: <input type="text" placeholder="(required)" name="title" value={this.state.title} onChange={this.handleInputChange} required autoComplete="off" /></label>
							</fieldset>

							<fieldset>
								<legend>Performance Dates</legend>
								<label>Start Date: <input type="date" name="starts" value={this.state.starts} placeholder="Production Name (required)" onChange={this.handleInputChange} required /></label>

								<label>End Date: <input type="date" name="ends" value={this.state.ends} placeholder="Production Name (required)" onChange={this.handleInputChange} required /></label>
							</fieldset>

							<fieldset>
								<legend>Notes</legend>
								<textarea name="notes" value={this.state.notes} onChange={this.handleInputChange}></textarea>
							</fieldset>

							<button type="submit">Save</button>
						</form>
					</section>
				</React.Fragment>
			);
		}

		return null;
	}
}

export default EditDetails;
