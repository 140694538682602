import React from "react";
import { Role } from "./Models.js";
import CheckboxSet, { FormOption } from "./CheckboxSet.js";
import cookie from 'react-cookies';

class AudienceDetails extends React.Component {
	constructor(props) {
		super(props);

		this.handleGroupChange = this.handleGroupChange.bind(this);
		this.handleParticipantChange = this.handleParticipantChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.state = {
			title: null,
			participants: null,
			groups0: null,
			groups1: null,
			groups2: null,
			groups3: null,
			cast: null,
			crew: null,
			musicians: null,
			other: null,
		};
	}

	handleGroupChange(e) {
		const target = e.target;
		const value = target.checked;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleParticipantChange(e) {
		const name = e.target.name;
		const item = e.target.value;
		const isChecked = e.target.checked;

		let copy = JSON.parse(JSON.stringify(this.state[name]));
		let member = new FormOption(item, item, isChecked);
		let index = copy.findIndex((element) => element.display === item);

		copy[index] = member;

		this.setState({
			[name]: copy
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.name === "title" ? target.value : target.checked;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmit = e => {
		e.preventDefault();

		var body = {};
		var audience = {};
		var participants = [];
		var i;

		//  extract the node list from the form
		//  it looks like an array, but lacks array methods
		var { cast, crew, musicians, other, groups0, groups1, groups2, groups3 } = this.form;

		// TODO: there has to be a better way
		cast = cast && cast.length === undefined ? [cast] : cast;
		crew = crew && crew.length === undefined ? [crew] : crew;
		musicians = musicians && musicians.length === undefined ? [musicians] : musicians;
		other = other && other.length === undefined ? [other] : other;

		// convert node list to an array
		const castArray = cast ? Array.prototype.slice.call(cast) : [];
		const crewArray = crew ? Array.prototype.slice.call(crew) : [];
		const musiciansArray = musicians ? Array.prototype.slice.call(musicians) : [];
		const otherArray = other ? Array.prototype.slice.call(other) : [];

		// extract only the checked checkboxes
		const castCheckboxes = castArray.filter(input => input.checked);
		const crewCheckboxes = crewArray.filter(input => input.checked);
		const musiciansCheckboxes = musiciansArray.filter(input => input.checked);
		const otherCheckboxes = otherArray.filter(input => input.checked);

		// use .map() to extract the value from each checked checkbox
		const castValues = castCheckboxes.map(input => input.value);
		const crewValues = crewCheckboxes.map(input => input.value);
		const musiciansValues = musiciansCheckboxes.map(input => input.value);
		const otherValues = otherCheckboxes.map(input => input.value);

		const groupsValues = [groups0.checked, groups1.checked, groups2.checked, groups3.checked];

		for (i = 0; i < castValues.length; i++) {
			participants.push({ type: 0, title: castValues[i] });
		}

		for (i = 0; i < crewValues.length; i++) {
			participants.push({ type: 1, title: crewValues[i] });
		}

		for (i = 0; i < musiciansValues.length; i++) {
			participants.push({ type: 2, title: musiciansValues[i] });
		}

		for (i = 0; i < otherValues.length; i++) {
			participants.push({ type: 3, title: otherValues[i] });
		}

		audience.title = this.state.title;
		audience.groups = groupsValues;
		audience.participants = participants;
		audience.id = this.props.match.params.id;

		body.sessionId = cookie.load('session_id');
		body.audience = audience;

		console.log(body);

		if (this.props.match.params.id === "new") {
			fetch(`https://beta.breaklegs.com/api/v1/audience/create2/`
				, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					body: JSON.stringify(body) // body data type must match "Content-Type" header
				})
		} else {
			fetch(`https://beta.breaklegs.com/api/v1/audience/participant/put2/`
				, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					body: JSON.stringify(body) // body data type must match "Content-Type" header
				})
		}
	};

	componentDidMount() {
		const request = async () => {
			const sessionId = cookie.load('session_id');

			if (this.props.match.params.id === "new") {
				this.setState({ title: "", groups0: false, groups1: false, groups2: false, groups3: false });
			}
			else {
				await fetch(
					`https://beta.breaklegs.com/api/v1/audience/participant/list2/?session_id=${sessionId}&room_id=${this.props.match.params.id}`,
					{
						cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					})
					.then(response => response.json())
					.then(data => this.setState({ participants: data.participants, groups0: data.groups[0], groups1: data.groups[1], groups2: data.groups[2], groups3: data.groups[3] }));

				const title = this.props.audiences.rooms.find(
					model => model.id === this.props.match.params.id
				).title;

				this.setState({ title });
			}

			const roles = this.props.members.flatMap(model =>
				model.roles
					.map(
						role =>
							new Role(
								role["type"],
								role["title"],
							)
					)
			).filter((model, index, orig) => orig.findIndex((element) => element.title === model.title) === index);

			const participants = (this.props.match.params.id === "new" ? [] : this.state.participants);

			const cast = roles.filter(model => model.type === 0).map(model => new FormOption(model.title, model.title, participants.findIndex((element) => element.type === 0 && element.title === model.title) >= 0));
			const crew = roles.filter(model => model.type === 1).map(model => new FormOption(model.title, model.title, participants.findIndex((element) => element.type === 1 && element.title === model.title) >= 0));
			const musicians = roles.filter(model => model.type === 2).map(model => new FormOption(model.title, model.title, participants.findIndex((element) => element.type === 2 && element.title === model.title) >= 0));
			const other = roles.filter(model => model.type === 3).map(model => new FormOption(model.title, model.title, participants.findIndex((element) => element.type === 3 && element.title === model.title) >= 0));

			this.setState({ cast, crew, musicians, other });
		}

		request();
	}

	render() {
		if (this.props.audiences && this.props.members && this.state.groups0 !== null && this.props.audiences && this.state.cast) {
			const cast = this.state.cast;
			const crew = this.state.crew;
			const musicians = this.state.musicians;
			const other = this.state.other;

			let specificCast = cast.length === 0 ? null :
				<React.Fragment>
					<fieldset>
						<legend>Select specific Cast</legend>
						<CheckboxSet setName={"cast"} setOptions={cast} onChange={this.handleParticipantChange} />
					</fieldset>
				</React.Fragment>;

			let specificCrew = crew.length === 0 ? null :
				<React.Fragment>
					<fieldset>
						<legend>Select specific Crew</legend>
						<CheckboxSet setName={"crew"} setOptions={crew} onChange={this.handleParticipantChange} />
					</fieldset>
				</React.Fragment>;

			let specificMusicians = musicians.length === 0 ? null :
				<React.Fragment>
					<fieldset>
						<legend>Select specific Musicians</legend>
						<CheckboxSet setName={"musicians"} setOptions={musicians} onChange={this.handleParticipantChange} />
					</fieldset>
				</React.Fragment>;

			let specificOther = other.length === 0 ? null :
				<React.Fragment>
					<fieldset>
						<legend>Select specific Other</legend>
						<CheckboxSet setName={"other"} setOptions={other} onChange={this.handleParticipantChange} />
					</fieldset>
				</React.Fragment>;

			return (
				<React.Fragment>
					<h1>{(this.props.match.params.id === "new" ? "New Audience" : "Audience Details")}</h1>

					<form onSubmit={this.handleSubmit} ref={form => (this.form = form)}>
						<fieldset className="simple">
							<legend>Audience Title</legend>
							<label>Title: <input type="text" placeholder="(required)" name="title" value={this.state.title} onChange={this.handleInputChange} required autoComplete="off" /></label>
						</fieldset>

						<fieldset>
							<legend>Cast</legend>

							<label><input type="checkbox" name="groups0" checked={this.state.groups0} onChange={this.handleGroupChange} />Include all Cast</label>

							{
								(this.state.groups0)
									? null
									: specificCast
							}
						</fieldset>

						<fieldset>
							<legend>Crew</legend>

							<label><input type="checkbox" name="groups1" checked={this.state.groups1} onChange={this.handleGroupChange} />Include all Crew</label>

							{
								(this.state.groups1)
									? null
									: specificCrew
							}
						</fieldset>

						<fieldset>
							<legend>Musicians</legend>

							<label><input type="checkbox" name="groups2" checked={this.state.groups2} onChange={this.handleGroupChange} />Include all Musicians</label>

							{
								(this.state.groups2)
									? null
									: specificMusicians
							}
						</fieldset>

						<fieldset>
							<legend>Other</legend>

							<label><input type="checkbox" name="groups3" checked={this.state.groups3} onChange={this.handleGroupChange} />Include all Other</label>

							{
								(this.state.groups3)
									? null
									: specificOther
							}
						</fieldset>

						<button type="submit">Save</button>
					</form>
				</React.Fragment>
			);
		}

		return null;
	}
}

export default AudienceDetails;
