import React, { Fragment } from "react";
import Audience from "./Audience.js";
import { Audience as AudienceModel } from "./Models.js";
import { Link } from "react-router-dom";

class EditEvents extends React.Component {
	render() {
		if (this.props.models !== null) {
			const models = this.props.models.rooms.map(
				model => new AudienceModel(model["title"], model["id"])
			);

			const listItems = models.map(model => (
				<li key={model.id}>
					<Link to={`/events/${model.id}`}>
						<Audience model={model} />
					</Link>
				</li>
			));

			return (
				<Fragment>
					<h1>Edit Events</h1>
					<ul>{listItems}</ul>
				</Fragment>
			);
		}

		return null;
	}
}

export default EditEvents;
