export class Production {
	constructor(title, starts, ends, image, notes) {
		this.title = title;
		this.starts = starts;
		this.ends = ends;
		this.image = image;
		this.notes = notes;
	}
}

export class DetailsHeader {
	constructor(name, secondary, tertiary, image, original) {
		this.original = original;
		this.image = image;
		this.name = name;
		this.secondary = secondary;
		this.tertiary = tertiary;
	}
}

export class Member {
	constructor(roles, id, accessCode, isAdmin, isActive, permissionSet, person) {
		this.id = id;
		this.roles = roles;
		this.accessCode = accessCode;
		this.isAdmin = isAdmin;
		this.isActive = isActive;
		this.permissionSet = permissionSet;
		this.person = person;
	}
}

export class Person {
	constructor(name, image, imageModified, isMinor, hasPin, hasEmail, bio) {
		this.name = name;
		this.image = image;
		this.imageModified = imageModified;
		this.isMinor = isMinor;
		this.hasPin = hasPin;
		this.hasEmail = hasEmail;
		this.bio = bio;
	}
}

export class PermissionsSet {
	constructor(hasEditEvents, hasEditMembership, hasEditFiles, hasEditAllEvents, hasAllPermissions, hasEditAudiences, hasEditDetails, audienceIds) {
		this.hasEditEvents = hasEditEvents;
		this.hasEditMembership = hasEditMembership;
		this.hasEditFiles = hasEditFiles;
		this.hasEditAllEvents = hasEditAllEvents;
		this.hasAllPermissions = hasAllPermissions;
		this.hasEditAudiences = hasEditAudiences;
		this.hasEditDetails = hasEditDetails;
		this.audienceIds = audienceIds;
	}
}

export class Role {
	constructor(type, title) {
		this.type = type;
		this.title = title;
	}
}

export class Audience {
	constructor(title, id) {
		this.id = id;
		this.title = title;
	}
}

export class Event {
	constructor(id, audienceId, starts, ends, title, notes, location) {
		this.id = id;
		this.audienceId = audienceId;
		this.starts = starts;
		this.ends = ends;
		this.title = title;
		this.notes = notes;
		this.location = location;
	}
}

export class Location {
	constructor(id, name, address) {
		this.id = id;
		this.name = name;
		this.address = address;
	}
}

export class FileUpload {
	constructor(id, audienceId, created, name, fileName, title, notes) {
		this.id = id;
		this.audienceId = audienceId;
		this.created = created;
		this.name = name;
		this.fileName = fileName;
		this.title = title;
		this.notes = notes;
	}
}