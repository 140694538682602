import React from "react";

class FileUpload extends React.Component {
	render() {
		const created = (new Date(this.props.model.created)).toLocaleString(undefined, { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" });

		return (
			<React.Fragment>
				<h3>{this.props.model.title}</h3>
				<p>Uploaded <time>{created}</time> by {this.props.model.name}</p>
			</React.Fragment>
		);
	}
}

export default FileUpload;
