import React from "react";

class Audience extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h3>{this.props.model.title}</h3>
			</React.Fragment>
		);
	}
}

export default Audience;
