import React, { Fragment } from "react";
import "./App.css";
import EditAudiences from "./EditAudiences";
import AudienceDetails from "./AudienceDetails";
import EditDetails from "./EditDetails";
import EditEvents from "./EditEvents";
import EditFileUploads from "./EditFileUploads";
import Events from "./Events";
import FileUploads from "./FileUploads";
import EventDetails from "./EventDetails";
import FileUploadDetails from "./FileUploadDetails";
import EditMembership from "./EditMembership";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import MemberDetails from "./MemberDetails";
import cookie from 'react-cookies'

class App extends React.Component {
	constructor(props) {
		super(props);

		this.updateEvents = this.updateEvents.bind(this);
		this.updateFiles = this.updateFiles.bind(this);

		this.state = {
			production: null,
			audiences: null,
			events: null,
			files: [],
			locations: null,
		};
	}

	updateEvents(events) {
		console.log("events");
		console.log(events);

		this.setState({ events });
	}

	updateFiles(files) {
		console.log("files");
		console.log(files);

		this.setState({ files });
	}

	componentDidMount() {
		const sessionId = cookie.load('session_id');

		if (sessionId === undefined) {
			document.location.href = "https://beta.breaklegs.com/app/";
		}

		const request = async () => {
			await fetch(
				`https://beta.breaklegs.com/api/v1/production/get2/?session_id=${sessionId}&access_code&pin&production_id&user=70cdc38b-8b98-4a75-a9ac-95afc5cba3e4`,
				{
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.setState({ production: data["production"] }));

			await fetch(
				`https://beta.breaklegs.com/api/v1/audience/list/all2/?session_id=${sessionId}&since=0001-01-01%2000:00:00%20+0000`
				, {
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.setState({ audiences: data }));

			await fetch(
				`https://beta.breaklegs.com/api/v1/location/list2/?session_id=${sessionId}&since=0001-01-01%2000:00:00%20+0000`
				, {
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.setState({ locations: data }));
		}

		request();
	}

	render() {
		if (this.state.production !== null && this.state.audiences !== null && this.state.locations !== null) {
			return (
				<Router>
					<NavStuffs isInApp={cookie.load('in-app')} /><main>
						<Route
							path="/details"
							render={props => (
								<EditDetails {...props} production={this.state.production} />
							)}
						/>
						<Route
							exact
							path="/audiences"
							render={props => (
								<EditAudiences {...props} models={this.state.audiences} />
							)}
						/>
						<Route
							path="/audiences/:id"
							render={props => (
								<AudienceDetails
									{...props}
									audiences={this.state.audiences}
									members={this.state.production.members}
								/>
							)}
						/>
						<Route
							exact
							path="/events"
							render={props => (
								<EditEvents
									{...props}
									models={this.state.audiences}
									events={this.state.events}
								/>
							)}
						/>
						<Route
							exact
							path="/events/:id"
							render={props => (
								<Events
									{...props}
									audiences={this.state.audiences}
									members={this.state.production.members}
									events={this.state.events}
									updateEvents={this.updateEvents}
								/>
							)}
						/>
						<Route
							path="/events/:id/:eventId"
							render={props => (
								<EventDetails
									{...props}
									audiences={this.state.audiences}
									events={this.state.events}
									locations={this.state.locations}
								/>
							)}
						/>
						<Route
							exact
							path="/files"
							render={props => (
								<EditFileUploads
									{...props}
									models={this.state.audiences}
									files={this.state.files}
								/>
							)}
						/>
						<Route
							exact
							path="/files/:id"
							render={props => (
								<FileUploads
									{...props}
									audiences={this.state.audiences}
									files={this.state.files}
								/>
							)}
						/>
						<Route
							path="/files/:id/:fileId"
							render={props => (
								<FileUploadDetails
									{...props}
									audiences={this.state.audiences}
									files={this.state.files}
								/>
							)}
						/>
						<Route
							exact
							path="/membership"
							render={props => (
								<EditMembership {...props} models={this.state.production.members} />
							)}
						/>
						<Route
							path="/membership/:id"
							render={props => (
								<MemberDetails
									{...props}
									audiences={this.state.audiences}
									members={this.state.production.members}
								/>
							)}
						/></main>
				</Router>
			);
		}

		return null;
	}
}

const NavStuffs = (props) => (
	<header>
		<nav>
			<ul>
				{(props["isInApp"] !== undefined)
					? null
					:
					<li className="exit">
						<a href="https://beta.breaklegs.com/app/"><strong>Exit</strong></a>
					</li>}
				<li>
					<NavLink activeClassName="selected" to="/details">Details</NavLink>
				</li>
				<li>
					<NavLink activeClassName="selected" to="/audiences">Audiences</NavLink>
				</li>
				<li>
					<NavLink activeClassName="selected" to="/events">Events</NavLink>
				</li>
				<li>
					<NavLink activeClassName="selected" to="/files">Files</NavLink>
				</li>
				<li>
					<NavLink activeClassName="selected" to="/membership">Membership</NavLink>
				</li>
			</ul>
		</nav>
	</header>
);

export default App;
