import React, { Fragment } from "react";
import FileUpload from "./FileUpload.js";
import { FileUpload as FileUploadModel } from "./Models.js";
import { Link } from "react-router-dom";
import cookie from 'react-cookies';

class Files extends React.Component {
	constructor(props) {
		super(props);

		this.deleteFile = this.deleteFile.bind(this);

		this.state = {
			title: null,
			files: null,
		};
	}

	deleteFile(e) {
		const id = e.target.dataset.id;
		const file = this.state.files.find(file => file.id === id);

		var body = {};

		body.sessionId = cookie.load('session_id');
		body.hasNotification = false;
		body.file = file;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/file/upload/delete2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			window.location.reload();
		});
	}

	componentDidMount() {
		const request = async () => {
			const sessionId = cookie.load('session_id');

			await fetch(
				`https://beta.breaklegs.com/api/v1/file/upload/list2/?session_id=${sessionId}&audience_id=${this.props.match.params.id}`,
				{
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				})
				.then(response => response.json())
				.then(data => this.setState({ files: data.files }));

			const title = this.props.audiences.rooms.find(
				model => model.id === this.props.match.params.id
			).title;

			this.setState({ title });
		}

		request();
	}

	render() {
		if (this.state.files !== null) {
			console.log(this.state.files);

			const models = this.state.files.map(
				model => new FileUploadModel(model["id"], model["audienceId"], model["created"], model["name"], model["fileName"], model["title"], model["notes"])
			);

			const listItems = models.map(model => (
				<li key={model.id}>
					<a href={`https://beta.breaklegs.com/uploads/files/${this.props.match.params.id}/${model.id}/${model.fileName}`}>
						<FileUpload model={model} />
					</a>
					{/* <button type="button" data-id={model.id} onClick={this.deleteEvent} className="inline tool destructive">Delete</button> */}
				</li>
			));

			return (
				<Fragment>
					<h1>Files <Link to={`/files/${this.props.match.params.id}/new`}>+</Link></h1>
					<section className="files">
						<ul className="files">{listItems}</ul>
					</section>
				</Fragment>
			);
		}

		return null;
	}
}

export default Files;
