import React from "react";

class SelectBoxSet extends React.Component {
	constructor(props) {
		super(props);

		this.handleKeyDown = this.handleKeyDown.bind(this);

	}

	handleKeyDown(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	}

	render() {
		if (!this.props.setOptions || this.props.setOptions.length === 0) {
			return <select></select>;
		}
		else {
			return (
				<select size={this.props.size} value={this.props.value} name={this.props.setName} onChange={this.props.onChange} onKeyDown={this.handleKeyDown}>
					{this.props.setOptions.map(option => {
						return (
							<option key={option.value} value={option.value}>
								{option.display}
							</option>
						);
					})}
				</select>
			);
		}
	}
}

export default SelectBoxSet;
