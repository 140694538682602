import React, { Fragment } from "react";
import DetailsHeader from "./DetailsHeader.js";
import { DetailsHeader as DetailsHeaderModel } from "./Models.js";
import { Link } from "react-router-dom";
import cookie from 'react-cookies';

class EditMembership extends React.Component {
	constructor(props) {
		super(props);

		this.duplicateMember = this.duplicateMember.bind(this);
		this.deleteMember = this.deleteMember.bind(this);
	}

	duplicateMember(e) {
		const id = e.target.dataset.id;
		const member = this.props.models.find(model => model.accessCode === id);
		const copy = JSON.parse(JSON.stringify(member));

		copy.accessCode = "";

		delete copy.person;

		var body = {};

		body.sessionId = cookie.load('session_id');
		body.member = copy;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/member/post2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			window.location.reload();
		});
	}

	deleteMember(e) {
		const id = e.target.dataset.id;
		const member = this.props.models.find(model => model.accessCode === id);

		var body = {};

		body.sessionId = cookie.load('session_id');
		body.member = member;

		console.log(body);

		fetch(`https://beta.breaklegs.com/api/v1/member/delete2/`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			body: JSON.stringify(body) // body data type must match "Content-Type" header
		}).then((response) => {
			return response.json();
		}).then((data) => {
			window.location.reload();
		});
	}

	render() {
		if (this.props.models !== null) {
			const models = this.props.models;

			const unclaimedCodes = models.filter(model => model.person === undefined).map(model => new DetailsHeaderModel("Member Code: " + model.accessCode, model.roles.map(role => role.title).join(", "), "", "no-avatar.png", model)).map(model => (
				<li key={Math.random()}>
					<Link to={`/membership/${model.original.accessCode}`}>
						<DetailsHeader model={model} tag="article" className="no_person" />
					</Link>
					<button type="button" data-id={model.original.accessCode} onClick={this.duplicateMember} className="inline tool copy">Copy</button>
					<button type="button" data-id={model.original.accessCode} onClick={this.deleteMember} className="inline tool destructive">Delete</button>
				</li>
			));

			const currentMembers = models.filter(model => model.isActive && model.person !== undefined).map(model => new DetailsHeaderModel(model.person.name, model.roles.map(role => role.title).join(", "), "", `person/${model.person.image}/full.jpg`, model)).map(model => (
				<li key={Math.random()}>
					<Link to={`/membership/${model.original.accessCode}`}>
						<DetailsHeader model={model} tag="article" />
					</Link>
					<button type="button" data-id={model.original.accessCode} onClick={this.duplicateMember} className="inline tool copy">Copy</button>
				</li>
			));

			const formerMembers = models.filter(model => !model.isActive).map(model => new DetailsHeaderModel(model.person.name, model.roles.map(role => role.title).join(", "), "", `person/${model.person.image}/full.jpg`, model)).map(model => (
				<li key={Math.random()}>
					<Link to={`/membership/${model.original.accessCode}`}>
						<DetailsHeader model={model} tag="article" />
					</Link>
					<button type="button" data-id={model.original.accessCode} onClick={this.duplicateMember} className="inline tool copy">Copy</button>
				</li>
			));

			return (
				<React.Fragment>
					<h1>Edit Membership <Link to={`/membership/new`}>+</Link></h1>

					<section className="membership">
						<h2>Unclaimed Member Codes</h2>
						<ul>{(unclaimedCodes.length > 0 ? unclaimedCodes : "There are no unclaimed member codes. Use the + above to create a new member.")}</ul>

						<h2>Current Members</h2>
						<ul>{currentMembers}</ul>

						{
							(formerMembers.length === 0)
								? null
								: <Fragment><h2>Former Members</h2>
									<ul>{formerMembers}</ul></Fragment>
						}
					</section>
				</React.Fragment>
			);
		}

		return null;
	}
}

export default EditMembership;
